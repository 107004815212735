/* Light theme specific overrides */
body {
  background-color: #ffffff;
  color: #000000;
}

/* Dark theme specific overrides */
body.dark-mode {
  background-color: #141414;
  color: #ffffff;
}

.header {
  display: flex;
  justify-content: space-between;
  align-items: center;
}

/* Add smooth transitions for theme switch */
body,
.ant-layout,
.ant-layout-header {
  transition: background-color 0.3s, color 0.3s;
}

.h-full {
  height: 100vh;
}

.flex-center {
  display: flex;
  align-items: center;
  justify-content: center;
}
